<template>
  <b-row>
    <b-col sm="12">
      <div class="mb-3">
        <b-tabs align="left">
          <b-tab v-for="(tab, index) in tabs" :key="index" :title="tab.title" :active="index == 0" lazy>
            <component :is="tab.component" />
            <!-- <IndexPurchaseImageTable :filterData="tab.filter" /> -->
          </b-tab>
        </b-tabs>
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'UserTransactionDatas',
  data() {
    return {
      tabs: [
        { title: 'ข้อมูล การซื้อ', filter: 'purchaseImage', component: 'IndexPurchaseImageTable' },
        { title: 'ข้อมูล การเติมเงิน', filter: 'creditPurchase', component: 'IndexPurchaseCreditTable' },
        // { title: 'ข้อมูล การขอเงินคืน', filter: 'creditPurchase', component: 'IndexCreditRefundTable' }, //ปิดเพราะย้ายไปไว้ที่เมนูแล้ว
      ],
    }
  },
  components: {
    IndexPurchaseImageTable: () => import('./Components/index/IndexPurchaseImageTable.vue'),
    IndexCreditRefundTable: () => import('./Components/index/IndexCreditRefundTable.vue'),
    IndexPurchaseCreditTable: () => import('./Components/index/IndexPurchaseCreditTable.vue'),
  },
}
</script>
